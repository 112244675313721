import { EinrideProvider } from "@einride/ui"
import { ReactNode } from "react"

type ColorMode = "light" | "dark"

const theme = {
  fonts: {
    body: "SuisseIntl, EinrideIcons, sans-serif",
    heading: "SuisseIntl, EinrideIcons, sans-serif",
  },
}

interface ThemeProviderProps {
  children: ReactNode
  colorMode: ColorMode
}

export const ThemeProvider = ({ children, colorMode }: ThemeProviderProps): JSX.Element => {
  return (
    <EinrideProvider colorMode={colorMode} theme={theme}>
      {children}
    </EinrideProvider>
  )
}
