import { Theme } from "@einride/ui"
import { SerializedStyles, css } from "@emotion/react"
import { useEffect } from "react"

// configuration options: http://browser-update.org/customize.html
// using slightly older browsers than what we support in browserslist
// as we only want to show the toaster to browsers that will likely crash
// rather than to browsers that will most likely work but with some "off" behaviour
const options = {
  required: {
    e: -4,
    f: -4,
    o: -4,
    s: -4,
    c: -4,
    samsung: -4,
    uc: -4,
  },
  // set this flag to true to force show the toaster when developing locally
  test: false,
  unsupported: true,
  insecure: true,
}

/*

*/
export const useBrowserSupport = (): void => {
  useEffect(() => {
    import("browser-update").then((browserUpdate) => {
      browserUpdate.default(options)
    })
  }, [])
}

export const browserSupportToasterStyle = (theme: Theme): SerializedStyles => css`
  #buorg {
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0px;
    left: 0px;
    border-bottom: none;
    text-align: center;
    animation: 1s ease-out 0s buorgfly;
  }

  .buorg-pad {
    background-color: #ffffff;
  }

  .buorg-moremsg,
  .buorg-mainmsg {
    font-family: ${theme.fonts.body};
    font-size: ${theme.fontSizes.md};
  }

  .buorg-buttons {
    display: block;
    text-align: center;
    margin: ${theme.spacer} 0;
    #buorgig,
    #buorgul,
    #buorgpermanent {
      font-family: ${theme.fonts.body};
      font-size: ${theme.fontSizes.md};
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      border-radius: 56px;
      min-width: 240px;
      background-color: black;
      &:hover {
        background-color: rgb(30, 30, 31);
      }
    }
  }
  @media screen and (max-width: 576px) {
    .buorg-buttons {
      #buorgig {
        margin-top: ${theme.spacer}px;
      }
    }
  }

  @keyframes buorgfly {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .buorg-fadeout {
    transition: visibility 0s 1s, opacity 2s ease-out 0.5s;
  }

  .buorg-icon {
    width: 22px;
    height: 16px;
    vertical-align: middle;
    position: relative;
    top: 0;
    display: inline-block;
    background: no-repeat 0px center;
  }
`
