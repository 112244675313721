/* eslint-disable react/no-unused-prop-types */
import { useBrowserSupport } from "components/fragments/Helmet/hooks/useBrowserSupport"
import { Helmet as ReactHelmet } from "react-helmet"

export const Helmet = (): JSX.Element => {
  useBrowserSupport()
  const links = [
    // Make Einride fonts available in the application
    {
      rel: "preconnect",
      href: "https://fonts.einride.tech",
    },
    {
      rel: "preconnect",
      href: "https://fonts.einride.tech",
      crossOrigin: "",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.einride.tech/v2/SuisseIntl.css",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.einride.tech/v3/fonts.css",
    },
  ]

  return <ReactHelmet link={links} />
}
