export const TABLET_BREAKPOINT = 768
export const DESKTOP_BREAKPOINT = 1440

export const breakpoint = {
  tablet: `@media (min-width: ${TABLET_BREAKPOINT}px)`,
  desktop: `@media (min-width: ${DESKTOP_BREAKPOINT}px)`,
}

export const mediaQueries = {
  belowSm: "(max-width: 575px)",
  sm: "(min-width: 576px)",
  belowMd: "(max-width: 849px)",
  md: "(min-width: 850px)",
  belowLg: "(max-width: 1199px)",
  lg: "(min-width: 1200px)",
}

export const cvars = {
  menuHeight: "var(--menu-height)",
  footerHeight: "var(--footer-height)",
  gridGutter: "var(--grid-gutter)",
  sectionFrameSpacing: "var(--section-frame-spacing)",
  sectionHeight: "var(--section-height)",
}
