import { Theme } from "@einride/ui"
import { SerializedStyles, css } from "@emotion/react"
import { browserSupportToasterStyle } from "components/fragments/Helmet/hooks/useBrowserSupport"
import { cvars } from "lib/theming/breakpoint"

export const globalStyles = (theme: Theme): SerializedStyles => css`
  main {
    min-height: calc(100vh - ${cvars.menuHeight} - ${cvars.footerHeight});
    width: 100%;
  }

  /* CSS variables */
  body {
    --menu-height: ${8 * theme.spacer}px;
    --grid-gutter: ${2 * theme.spacer}px;
    --section-frame-spacing: ${2 * theme.spacer}px;
    --section-height: ${100 * theme.spacer}px;
    --footer-height: 0px;

    @media ${theme.mediaQueries.md} {
      --menu-height: ${11 * theme.spacer}px;
      --grid-gutter: ${3 * theme.spacer}px;
      --section-frame-spacing: ${3 * theme.spacer}px;
      --section-height: ${122 * theme.spacer}px;
    }
  }

  p:last-of-type:empty {
    margin: 0;
  }

  ${browserSupportToasterStyle(theme)}

  /* Cookie Dialog */

  #CybotCookiebotDialog {
    top: auto !important;
    left: 8px !important;
    right: 8px !important;
    bottom: 16px !important;
    width: auto !important;
    min-width: 0 !important;
    max-width: 100% !important;
    max-height: calc(100vh - 32px) !important;
    border-radius: 24px !important;
    box-shadow: none !important;
    transform: translate(0, 0) !important;
  }

  #CybotCookiebotDialog * {
    font-size: ${theme.fontSizes.md} !important;
    line-height: 24px !important;
    font-family: "SuisseIntl", sans-serif !important;
    font-weight: ${theme.fontWeights.book} !important;
  }

  #CybotCookiebotDialogBodyContent {
    padding: 15px 7px;
  }

  #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border-bottom: none !important;
  }

  #CybotCookiebotDialogBodyLevelButtonsSelectPane
    label:not([for="CybotCookiebotDialogBodyLevelButtonNecessary"])
    .CybotCookiebotDialogBodyLevelButtonDescription:hover {
    color: inherit !important;
  }

  .CybotCookiebotDialogBodyBottomWrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  #CybotCookiebotDialogHeader {
    display: none !important;
  }

  #CybotCookiebotDialogBodyContentTitle {
    margin-bottom: 16px !important;
  }

  .CybotCookiebotFader {
    display: none !important;
  }

  .CybotCookiebotScrollContainer {
    border-bottom: none !important;
  }

  .CybotCookiebotDialogBodyLevelButtonWrapper {
    flex-direction: row-reverse !important;
    justify-content: flex-end !important;
    padding: 12px 16px 12px 35px !important;
    border: none !important;
  }

  .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
    width: 24px !important;
    height: 24px !important;
    margin: 0 16px 0 0 !important;
    flex-shrink: 0;
  }

  .CybotCookiebotDialogBodyLevelButtonSlider {
    border-radius: 8px !important;
    background: #ffffff !important;
  }

  .CybotCookiebotDialogBodyLevelButtonSlider::before {
    top: 0 !important;
    left: 0 !important;
    font-family: EinrideIcons, sans-serif;
    font-size: ${theme.fontSizes.md};
    text-align: center;
    color: #1e1e1f !important;
    content: "✓" !important;
    background: none !important;
    opacity: 0;
    transform: translate(0, 0) !important;
  }

  #CybotCookiebotDialogTabContent
    input:checked
    + .CybotCookiebotDialogBodyLevelButtonSlider::before {
    transform: translate(0);
    opacity: 1;
  }

  #CybotCookiebotDialogTabContent input:disabled + .CybotCookiebotDialogBodyLevelButtonSlider {
    background: none !important;
  }

  #CybotCookiebotDialogTabContent
    input:disabled
    + .CybotCookiebotDialogBodyLevelButtonSlider::before {
    color: #ffffff !important;
  }

  #CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
    border: thin solid #d6d6d6 !important;
    background: none !important;
  }

  #CybotCookiebotDialogBodyEdgeMoreDetails {
    justify-content: flex-start !important;
    margin: 10px 0em 0.5em 16px !important;
  }

  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    color: ${theme.colors.content.tertiary} !important;
    text-decoration: underline !important;
  }

  #CybotCookiebotDialogBodyEdgeMoreDetailsLink::after {
    display: none !important;
  }

  .CybotCookiebotBannerCloseButton {
    display: none !important;
  }

  .CybotCookiebotDialogDetailBodyContentCookieLink {
    text-decoration: underline !important;
  }

  #CybotCookiebotDialogNav {
    border-bottom: none !important;
  }

  .CybotCookiebotDialogDetailBodyContentCookieGroup {
    border-color: #7a7a7a !important;
  }

  .CybotCookiebotDialogNavItemLink {
    color: ${theme.colors.content.tertiary} !important;
  }

  .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    color: #ffffff !important;
    border: none !important;
    border-bottom: thin solid #ffffff !important;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerHeader
    .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
    margin-top: 5px !important;
  }

  .CookieCard {
    border-bottom-color: #7a7a7a !important;
  }

  .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton::before {
    top: -6px;
    vertical-align: initial;
  }
  .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover {
    color: inherit !important;
  }

  #CybotCookiebotDialogPoweredByText * {
    font-size: 15px !important;
  }

  #CybotCookiebotDialogPoweredByText a {
    color: #ffffff !important;
  }

  .CybotCookiebotDialogActive:not(.CybotEdge) #CybotCookiebotDialogFooter {
    position: static !important;
  }

  .CybotCookiebotDialogBodyButton {
    height: 48px;
    padding: 0px 16px !important;
    margin-bottom: 16px !important;
    white-space: nowrap;
    border-radius: 24px !important;
    border: none !important;
  }

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    order: 1;
  }

  #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
    flex-direction: row !important;
  }

  /* Mobile only */

  @media only screen and (max-width: 600px) {
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
      order: 2;
      margin-right: 4px;
    }

    #CybotCookiebotDialogBodyButtonDecline {
      order: 3;
      margin-left: 4px;
    }

    .CybotCookiebotDialogBodyButton {
      width: calc(50% - 4px) !important;
    }

    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
      width: 100% !important;
    }

    #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
      flex-wrap: wrap;
    }
  }

  /* Tablet and up */

  @media only screen and (min-width: 601px) {
    .CybotCookiebotDialogBodyLevelButtonWrapper {
      padding-left: 0px !important;
    }

    .CybotCookiebotDialogBodyLevelButtonLabel {
      margin-bottom: 0 !important;
    }
  }

  /* Tablet only */

  @media only screen and (min-width: 601px) and (max-width: 1279px) {
    #CybotCookiebotDialogBodyFieldsetInnerContainer {
      padding: 0 16px !important;
    }
  }

  /* Small desktop and up */

  @media only screen and (min-width: 1280px) {
    #CybotCookiebotDialog {
      top: auto !important;
      left: 48px !important;
      right: 48px !important;
      bottom: 24px !important;
      padding: 48px !important;
      border-radius: 32px !important;
    }

    #CybotCookiebotDialogBodyContent {
      display: flex;
    }

    .CybotCookiebotDialogBodyBottomWrapper {
      flex-direction: row-reverse !important;
      border: none !important;
      justify-content: space-between !important;
    }

    #CybotCookiebotDialogBodyContentTitle {
      flex-shrink: 0;
      margin-right: 24px !important;
    }

    .CybotCookiebotDialogBodyLevelButtonWrapper {
      padding-right: 19px !important;
    }

    .CybotCookiebotDialogBodyLevelButtonWrapper:last-child {
      margin-right: 0 !important;
      padding-right: 0px !important;
    }

    #CybotCookiebotDialogBodyEdgeMoreDetails {
      margin-left: 0 !important;
    }

    .CybotCookiebotDialogDetailBodyContentCookieTypeIntro {
      max-width: 50%;
    }

    #CybotCookiebotDialogDetailBodyContentTextAbout {
      max-width: 50%;
    }

    #CybotCookiebotDialogBodyContentText {
      max-width: 325px;
    }

    .CybotCookiebotDialogBodyButton {
      margin-left: 24px;
      margin-bottom: 0 !important;
    }

    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
      padding-top: 0 !important;
    }

    #CybotCookiebotDialog.CybotEdge {
      #CybotCookiebotDialogTabContent {
        margin-left: 0 !important;
      }

      #CybotCookiebotDialogFooter {
        padding: 0 !important;
        right: 0 !important;
      }
    }

    .CybotCookiebotDialogActive:not(.CybotEdge) {
      #CybotCookiebotDialogFooter {
        padding: 0 !important;
        padding-top: 1em !important;
        #CybotCookiebotDialogBodyButtonsWrapper {
          flex-direction: row !important;
        }
      }
    }
  }

  /* Small desktop only */

  @media only screen and (min-width: 1280px) and (max-width: 1509px) {
    #CybotCookiebotDialogBodyContent {
      min-height: 200px;
    }

    .CybotCookiebotDialogBodyBottomWrapper {
      margin: 0 !important;
    }

    .CybotCookiebotDialogActive:not(.CybotEdge) {
      #CybotCookiebotDialogFooter {
        padding-top: 1em !important;
        #CybotCookiebotDialogBodyButtonsWrapper {
          flex-direction: row !important;
        }
      }
    }

    #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
      flex-direction: column-reverse !important;
    }

    .CybotCookiebotDialogActive.CybotEdge #CybotCookiebotDialogFooter {
      .CybotCookiebotDialogBodyButton {
        margin-bottom: ${theme.spacer * 2}px !important;
      }
    }
  }

  /* Large desktop and up */

  @media only screen and (min-width: 1510px) {
    #CybotCookiebotDialogFooter {
      position: absolute !important;
    }

    .CybotCookiebotDialogBodyButton {
      width: auto !important;
      flex-shrink: 0;
    }

    #CybotCookiebotDialogBodyContentText {
      max-width: 455px !important;
    }

    .CybotCookiebotDialogActive:not(.CybotEdge) {
      #CybotCookiebotDialogBodyContent {
        display: block;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
`
