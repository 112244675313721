/* eslint-disable react/jsx-filename-extension */
import { Global } from "@emotion/react"
import { ErrorBoundary } from "@sentry/gatsby"
import { Helmet } from "components/fragments/Helmet/Helmet"
import { globalStyles } from "components/shared/styles/Global"
import { ThemeProvider } from "lib/theming/ThemeProvider/ThemeProvider"
import "whatwg-fetch"

// had to remove the theme because of a bug in emotion styled library causing a client rerender
/* const StyledRoot = styled.div`
  color: #1e1e1f;
  background: #ffffff;
` */

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <ThemeProvider colorMode="dark">
        <Helmet />
        <Global styles={globalStyles} />
        <ThemeProvider colorMode="light">
          <div
            style={{
              color: "#1e1e1f",
              background: "#ffffff",
            }}
          >
            {element}
          </div>
        </ThemeProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log("onRouteUpdate")
  if (location && location.state) {
    // eslint-disable-next-line no-param-reassign
    location.state.referrer = prevLocation ? prevLocation.pathname : null
  }
  if (!prevLocation && !location.hash) {
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: "instant" }), 0)
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  console.log("shouldUpdateScroll")
  window.history.scrollRestoration = "manual"
  const currentPosition = getSavedScrollPosition(location) || [0, 0]

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`)
      item?.scrollIntoView({ behavior: "smooth" })
    } else {
      // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: "instant",
      })
    }
  }, 0)

  // do not let gatsby do anything more
  return false
}
